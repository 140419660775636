<template>
  <el-container>
    <el-dialog
      :title=" isAdd ? '添加安装包' : '编辑安装包' "
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="800px" >
      <div>
         <!-- 模板管理 -->
        <el-form ref="form" :model="form" :rules="rules" label-width="150px" >
			<el-form-item label="应用名称"  prop="name">
                <el-input v-model="form.name"  placeholder="请输入App名称"></el-input>
            </el-form-item>
            <el-form-item label="应用版本号"  prop="version">
                <el-input v-model="form.version"  placeholder="请输入版本号"></el-input>
            </el-form-item>
            <el-form-item label="安装包地址"  prop="resource">
                <el-input v-model="form.resource"  placeholder="请输入安装包地址"></el-input>
            </el-form-item>
			<el-form-item label="升级方式"  prop="isWgt">
				<el-radio v-model="form.isWgt" :label="0">全量升级</el-radio>
				<el-radio v-model="form.isWgt" :label="1">增量升级</el-radio>
            </el-form-item>
            <el-form-item label="备注"  prop="remark">
                <el-input v-model="form.remark" type='textarea' rows='4'  placeholder="请输入备注信息"></el-input>
            </el-form-item>
            <!-- <el-form-item label="安装包文件"  prop="md5" >
                <el-upload
                drag
                action="none"
                accept="doc, docx, dot, dotx, xls, xlsx, xlt, xltx, csv, ppt, pptx, pot, potx, pdf, txt, xml, htm, html, jpg, png, bmp, gif, tiff, ofd, jpeg, wps"
                :auto-upload="false"
                :on-change="onUploadChanged"
                :show-file-list="true"
                :multiple="false"
                :limit='1'
                >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </el-upload>
            </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <div></div>
        <div>
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </div>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import { msgAPI } from 'liankong-ui-api' 
export default {
	data() {
		return {
			dialogVisible:false,
			isAdd:false,
			// 模板管理
			form:{
				name:'',
				isWgt:0,
				version:'',
				resource:'',
				remark:''
			},
			rules:{
				version:[{ required: true, message: '请输入应用版本号', trigger: 'blur' }],
				name:[{ required: true, message: '请输入应用名称', trigger: 'blur' }],
				resource:[{required: true,  message: '请输入安装包地址', trigger: 'blur'}],
				isWgt:[{required: true,  message: '请选择升级方式', trigger: 'blur'}]
			},
		};
	},
	methods: {
		show(flag,row){
			this.isAdd = flag;
			if(this.isAdd){
				this.form = {
					name:'',
					isWgt:0,
					version:'',
					resource:'',
					remark:''
				};
			}else{
				this.form = {
					id:row.id,
					name:row.name,
					isWgt:row.isWgt,
					version:row.version,
					resource:row.resource,
					remark:row.remark
				};
			}
		},
		// onUploadChanged(file, List) {
		// 	// 单个文件
		// 	this.selectFile = file;
		// 	this.$options.filters["fileMD5"](file, this.progressHandle);
		// },
		// 提交数据
		submit(){
			this.$refs['form'].validate((valid) => {
				if (valid) {
					if(this.isAdd){
						// 添加数据
						msgAPI.addApp(this.form).then(res=>{
							if(res.code == 0){
								this.dialogVisible = false;
								this.$parent.getList()
								this.$message.success('添加成功！');
							}else{
								this.$message.error(res.message);
							}
						}).catch(res=>{
							this.$message.error(res.message);
						})
					}else{
						// 修改数据
						msgAPI.updateApp(this.form).then(res=>{
							if(res.code == 0){
								this.dialogVisible = false;
								this.$parent.getList()
								this.$message.success('编辑成功！');
							}else{
								this.$message.error(res.message);
							}
						}).catch(res=>{
							this.$message.error(res.message);
						})
					}
				} else {
					return false;
				}
			});
		},
		//文件处理进度 base64
		// progressHandle(err, data){
		// 	if(err){
		// 		return
		// 	}
		// 	if(data.state === "end"){
		// 		// 上传文件转为base64并上传
		// 		this.form.md5 = data.md5;
		// 		let _this = this;
		// 		let reader = new FileReader();
		// 		// 获取上传文件类型
		// 		const fileSuffix = this.selectFile.name.substring(this.selectFile.name.lastIndexOf('.')+1);
		// 		reader.onload = function () {
		// 		const base64s = reader.result.substring(reader.result.indexOf('base64,')+7)
		// 		gatherAPI.uploadFile({
		// 			'base64FileData': base64s,
		// 			'md5': data.md5,
		// 			'type': 1, 
		// 			'suffix':fileSuffix
		// 		}).then(res=>{
		// 			if(res.code == 0){
		// 			_this.$message.success('文件上传成功')
		// 			_this.form.path = res.data;
		// 			}else{
		// 			_this.$message.error(res.message)
		// 			}
		// 		}).catch(res=>{
		// 			_this.$message.error(res.message)
		// 		})
		// 		}
		// 		reader.readAsDataURL(this.selectFile.raw);
		// 	}
		// },
	},
	created() {
	},
};
</script>
<style lang='less' scoped>
.dialog-footer{
  display: flex;
  justify-content: space-between;
}
/deep/.el-select{
  width: 100%;
}

</style>