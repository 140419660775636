<template>
    <div class="page-container">
       <div class="top-box">
            <div>
                <el-button type='primary' @click="handleClick(null,'add')"><i class='el-icon-plus'></i> 新增</el-button>  
            </div>
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="版本号">
                    <el-input v-model="query.name" placeholder="请输入版本号" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
                </el-form-item>
            </el-form>
       </div>

        <div class="table-box">
            <el-table
                :data="tableData"
                border
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                type="index"
                label="序号"
                align="center"
                width="150">
                </el-table-column>
                <el-table-column
                prop="name"
                label="版本名称"
                align="center">
                </el-table-column>
                <el-table-column
                prop="version"
                label="版本号"
                align="center">
                </el-table-column>
                <el-table-column
                prop="isWgt"
                label="升级方式"
                align="center">
                <template slot-scope="scope">
                        {{scope.row.isWgt == 0 ? '全量升级' : '增量升级'}}
                    </template>
                </el-table-column>
                 <el-table-column
                prop="isCurrent"
                label="发布版本"
                align="center">
                <template slot-scope="scope">
                        {{scope.row.isCurrent == 0 ? '否' : '是'}}
                    </template>
                </el-table-column>
                <el-table-column
                prop="remark"
                label="备注"
                align="center">
                </el-table-column>
                 <el-table-column
                prop="documentName"
                label="创建时间"
                align="center">
                    <template slot-scope="scope">
                        {{scope.row.createDate | date}}
                    </template>
                </el-table-column>
                <el-table-column
                label="操作"
                align="center">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row,'edit')" type="text" size="small" style='color:#E6A23C'>编辑</el-button>
                        <el-button @click="handleClick(scope.row,'delete')" type="text" size="small" style='color:#F56C6C'>删除</el-button>
                        <el-button v-if='scope.row.isCurrent == 0' @click="handleClick(scope.row,'public')" type="text" size="small" style='color:#67C23A'>发布版本</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-pagination
        class="pagebox"
        @current-change="handleCurrentChange"
        :current-page="query.pageNum"
        :page-size="query.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
        
        <upload ref='upload'></upload>

    </div>
</template>
<script>
    import upload from './upload.vue'
    import { msgAPI } from 'liankong-ui-api' 
    export default {
        components: {
            upload
        },
        data() {
            return {
                total: 0,
                query: {
                    name:'',
                    pageNum: 1,
                    pageSize: 10
                },
                tableData: [],
                selectedList:[]
            }
        },
        created(){
            this.getList()
        },
        methods: {
            onSearch(){
                this.query.pageNum = 1;
                this.getList()
            },
            handleCurrentChange(num){
                this.query.pageNum = num;
                this.getList()
            },
            handleSelectionChange(list){
                this.selectedList = list;
            },
            handleClick(rows,type){
                switch(type){
                    case 'add':
                        this.$refs['upload'].dialogVisible = true;
                        this.$refs['upload'].show(true)
                    break;
                    case 'edit':
                        this.$refs['upload'].dialogVisible = true
                        this.$refs['upload'].show(false,rows)
                    break;
                    case 'delete':
                        this.$confirm('您确定要删除当前选中的版本吗, 是否继续?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(()=>{
                            msgAPI.deleteAppById(rows.id).then((res)=>{
                                if(res.code==0){
                                    this.getList()
                                    this.$message.success('删除成功')
                                } else {
                                    this.$message.error('删除失败')
                                }
                            })
                        }).catch(()=>{})
                    break;
                    case 'public':
                         this.$confirm('您确定要设置当前版本为发布版本吗, 是否继续?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(()=>{
                            msgAPI.setCurrtentVersion(rows.id).then((res)=>{
                                if(res.code==0){
                                    this.getList()
                                    this.$message.success('设置成功')
                                } else {
                                    this.$message.error(res.message)
                                }
                            })
                        }).catch(()=>{})
                    break;
                }
            },
            getList(){
                msgAPI.getAppList(this.query).then(res => {
                    if(res.code == 0){
                        this.tableData = res.data;
                        this.total = res.total;
                    }else{
                        this.$message.error(res.message)
                    }
                })
            }
        }
    }
</script>
<style lang="less" scoped>

.pagebox {
    padding: 20px 20px 0px 20px;
}
.top-box{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.page-container{
    padding: 20px;
}
</style>